<template>
  <div class=container>
<td class=table width="73%" height="100%" align="left" valign="baseline" bgcolor="#FFFFFF">      <div align="center">        <table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center">
              <p><font size="+2">Columbia University Image Library<br>
                (COIL-20) </font></p>
              </div></td>
          </tr>
          <tr>
            <td><div align="center"><img src="https://cave.cs.columbia.edu/old/software/softlib/gif/20objects.jpg" width="600" height="400"></div></td>
          </tr>
          <tr>
            <td><span class="style3">Access Instructions</span>
              <p> To database is available in two versions. The first, [<a href="https://cave.cs.columbia.edu/old/databases/SLAM_coil-20_coil-100/coil-20/coil-20-unproc.zip">unprocessed</a>], consists of images for
                  five of the objects that contain both the object and the background. The second, [<a href="https://cave.cs.columbia.edu/old/databases/SLAM_coil-20_coil-100/coil-20/coil-20-proc.zip">processed</a>], 
                  contains images for all of the objects in which the background has been discarded (and the images consist of the smallest square that contains the object). For formal documentation look at the corresponding compressed technical report, 
                  [<a href="https://cave.cs.columbia.edu/old/databases/SLAM_coil-20_coil-100/coil-20/coil-20.ps.zip">gzipped</a>]. <br>
              </p>
              <dl>
                <dl>
                  <dt>"Columbia Object Image Library (COIL-20),"
                  </dt><dd>S. A. Nene, S. K. Nayar and H. Murase,
                  </dd><dd>Technical Report CUCS-005-96, February 1996.
                  </dd><dd>[<a href="https://cave.cs.columbia.edu/old/databases/papers/nene/nene-nayar-murase-coil-20.ps.gz">gzipped</a>][<a href="https://cave.cs.columbia.edu/old/databases/papers/nene/nene-nayar-murase-coil-20.ps">uncompressed</a>]
                </dd></dl>
                <br>
              </dl>
              <hr size="5">
              <address>
              <a href="mailto:webcave@lists.cs.columbia.edu"><br>
            Contact person for COIL</a>
            </address>              </td></tr>
        </tbody></table>
        <br>    
        <br>
        <br>
        <br>
        <br>
        </div>
    </td>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:0 auto;
}
</style>